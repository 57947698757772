//@ts-ignore
export default {
  rayban: {
    en_US: {
      steps: {
        tilesTitlePrefixProceed: "Proceed with",
        tilesTitlePrefixUpgrade: "Upgrade to",
        prescription: {
          prescriptionValues: "Prescription preferences",
        },
        advancedPrescription: {
          title: "Do you have a prescription?",
          manual: {
            addPrismValues: "Add prism values",
            pupillaryDistanceSubtitle:
              "You can select the default settings of <b>61 for women</b> and <b>64 for men</b> if you have an average or low prescription. If you have a strong prescription (or if you want to know your exact PD), please ",
            whatIsIt: "How to measure your PD",
            commentsTitle: "Comments",
            alerts: {
              pdValueWarningSmaller:
                "<b>The selected PD is smaller than average, we suggest double checking your prescription.</b></br>You can leave the default setting of 63 which is the average measurement for adults.</br>If we need any more information about your prescription, one of our experts will be in touch.",
              pdValueWarningLarger:
                "<b>The selected PD is larger than average, we suggest double checking your prescription.</b></br>You can leave the default setting of 63 which is the average measurement for adults.</br>If we need any more information about your prescription, one of our experts will be in touch.",
              axisRangeError:
                "The AXIS values you’ve inserted are not correct! These values must be between 1 and 180.",
              axisZeroError:
                "The AXIS values you’ve inserted are not correct! If CYL value is higher or lower than 0, AXIS values can’t be 0.",
              sphPositiveNegativeWarning:
                "<b>You’ve inserted 1 positive and 1 negative value, which is very rare.</b><br>We suggest double checking your prescription. If this is correct, you can proceed.",
              pdValueWarning:
                "The selected PD is outside the normal range, we suggest double checking your prescription. You can leave the default setting of 63 which is the average measurement for adults. \nIf we need any more information about your prescription, one of our experts will be in touch.",
              requiredToProceed: "This is required to proceed",
              incompatibleWithPrescriptionBody:
                "but don’t worry, compatible frames are available",
              agreementText:
                "By clicking this box, I confirm that the prescription values entered above are taken from a valid (not expired) prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
            },
            clearAll: "Clear all",
            selectNewFrame: "Select new frame",
            continueModal: {
              title: "Continue with non-prescription",
              message:
                "You have not entered a prescription for your glasses. Would you like for us to process your order with Non-Prescription lenses?",
              continue: "Yes, continue",
              cancel: "No, go back",
            },
            incompatibleFrame:
              "We're sorry – the frame you’ve chosen isn't compatible with your prescription. Please select another style.\n </br>Have questions? You can <a>contact our Customer Service</a> team.",
          },
          account: {
            card: {
              description: "Log in and select your prescription.",
            },
          },
          upload: {
            card: {
              description:
                "And we'll take care of the rest. We accept the following file formats: ###FILE_FORMATS### (max ###FILE_SIZE### MB) and IWork pages",
            },
            review: {
              title: "Uploaded",
            },
            prescriptionUploaded: "Prescription Uploaded",
            pdForm: {
              pdValueWarning:
                "The selected PD is outside the normal range, we suggest double checking your prescription. You can leave the default setting of 63 which is the average measurement for adults. \nIf we need any more information about your prescription, one of our experts will be in touch.",
            },
            filePreviewTitle: "Your prescription",
          },
          doctor: {
            pdForm: {
              pdValueWarning:
                "The selected PD is outside the normal range, we suggest double checking your prescription. You can leave the default setting of 63 which is the average measurement for adults. \nIf we need any more information about your prescription, one of our experts will be in touch.",
            },
          },
          later: {
            card: {
              description:
                "We'll ask for it after you order, either by uploading it or having us call your doctor.",
            },
          },
          californian: {
            toggle: "Are you a California resident?",
            info: "Please note that besides adding prescription values manually, California residents are also required to electronically transmit their valid prescription. Choose an option to proceed.",
            uploadTitle: "Upload",
            uploadDesc:
              "We accept the following file formats: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max 10 MB) and IWork pages.",
            uploadButton: "Upload",
            callMyDoc: "Call my doctor",
            callMyDocButton: "FIND YOUR DOCTOR",
            yes: "YES",
            no: "NO",
            modalMissingPrescription: {
              title: "Missing prescription values",
              subtitle:
                "If you don’t fill the values we won’t be able to suggest the right lens for you",
              bottonYes: "YES, CONTINUE",
              bottonNo: "NO, GO BACK",
            },
          },
        },
        type: {
          moreInfo: "More info",
        },
        treatmentsFamily: {
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included.",
        },
        designType: {
          confirmedTitle: "Design Type",
          title: "Choose your lens quality",
          subtitle:
            "State-of-the-art lenses made with Ray-Ban's unique lens technology, complete with the brand's iconic signature etching.",
        },
        treatments: {
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          openButtonLabel: "try on",
        },
        color: {
          polarized: "Polarized only",
          tooltip: {
            polarized:
              "Reduces glare in extra bright light, for superior clarity and protection of the eye.",
            blueLight:
              "Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue",
          },
        },
        addOns: {
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant and UV protection are included.",
        },
        review: {
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology.",
          paymentInstallments: {
            installmentsLabel:
              "Pay over time in interest-free installments with ###PAYMENT_TYPE###",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          tooltip:
            "Ensure your eyewear with an extended warranty for unlimited repair and replacement at any LensCrafters store.",
        },
      },
      price: {
        vatIncluded: "Tax included",
      },
      and: "&",
      exitTitle: "Are you sure you want to exit?",
      exitSubtitle: "Your lens selection will not be saved",
      frameSize: "Frame size",
      yourSelections: "Your selections",
      generic: {
        modal: {
          close: "",
        },
      },
    },
    en_GB: {
      steps: {
        advancedPrescription: {
          manual: {
            whatIsIt: "How to measure your PD",
            alerts: {
              sphPositiveNegativeWarning:
                "<b>You’ve inserted 1 positive and 1 negative value, which is very rare.</b><br>We suggest double checking your prescription. If this is correct, you can proceed.",
            },
          },
          upload: {
            card: {
              title: "Upload now",
              description:
                "And we'll take care of the rest. We accept the following file formats: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max10MB) and IWork pages",
            },
          },
          later: {
            card: {
              description:
                "We'll ask for it after you order, either by uploading it or having us call your optician.",
            },
          },
        },
        treatmentsFamily: {
          subtitle:
            "Your glasses will come with best-in-class Ray-Ban technology. Scratch resistant and UV protection are included.",
        },
        thickness: {
          designTypeTitle: "Choose your lens design",
        },
        color: {
          tooltip: {
            switch:
              "Reduces glare in extra bright light, for superior clarity and eye protection.",
            polarized:
              "Reduces glare in extra bright light, for superior clarity and eye protection.",
            blueLight:
              "Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue",
          },
        },
        addOns: {
          subtitle:
            "Your glasses will come with best-in-class Ray-Ban technology. Scratch-resistant and UV protection are included",
        },
        review: {
          subtitle:
            "Your glasses will come with best-in-class Ray-Ban technology.",
          paymentInstallments: {
            installmentsLabel:
              "Pay over time in interest-free instalments with ###PAYMENT_TYPE###",
            modal: {
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
            },
          },
          tooltip:
            "Insure your eyewear with an extended warranty for unlimited repair and replacement at any LensCrafters store.",
        },
      },
      yourSelections: "Your selections",
    },
    en_CH: {
      steps: {
        advancedPrescription: {
          manual: {
            whatIsIt: "How to measure your PD?",
            alerts: {
              sphPositiveNegativeWarning:
                "<b>You’ve inserted 1 positive and 1 negative value, which is very rare.</b><br>We suggest double checking your prescription. If this is correct, you can proceed.",
            },
          },
          upload: {
            card: {
              title: "Upload now",
              description:
                "And we'll take care of the rest. We accept the following file formats: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max10MB) and IWork pages",
            },
          },
          later: {
            card: {
              description:
                "We'll ask for it after you order, either by uploading it or having us call your optician.",
            },
          },
        },
        treatmentsFamily: {
          subtitle:
            "Your glasses will come with best-in-class Ray-Ban technology. Scratch resistant and UV protection are included.",
        },
        thickness: {
          designTypeTitle: "Choose your lens design",
        },
        color: {
          tooltip: {
            switch:
              "Reduces glare in extra bright light, for superior clarity and eye protection.",
            polarized:
              "Reduces glare in extra bright light, for superior clarity and eye protection.",
            blueLight:
              "Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue",
          },
        },
        addOns: {
          subtitle:
            "Your glasses will come with best-in-class Ray-Ban technology. Scratch-resistant and UV protection are included",
        },
        review: {
          subtitle:
            "Your glasses will come with best-in-class Ray-Ban technology.",
          paymentInstallments: {
            installmentsLabel:
              "Pay over time in interest-free instalments with ###PAYMENT_TYPE###",
            modal: {
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
            },
          },
          tooltip:
            "Insure your eyewear with an extended warranty for unlimited repair and replacement at any LensCrafters store.",
        },
      },
      yourSelections: "Your selections",
    },
    en_MX: {
      steps: {
        advancedPrescription: {
          manual: {
            review: {
              title: "Se ingresan manualmente",
            },
            addPrismValues: "Agregar valores de prisma",
            alerts: {
              sphPositiveNegativeWarning:
                "<b>You’ve inserted 1 positive and 1 negative value, which is very rare.</b><br>We suggest double checking your prescription. If this is correct, you can proceed.",
              incompatibleWithPackagesTitle:
                "Lo sentimos, esta graduación no es compatible con los lentes disponibles para este tipo de armazón.",
            },
          },
          upload: {
            card: {
              description:
                "And we'll take care of the rest. We accept the following file formats: .pdf, .png, .jpeg, .gif, .tiff, .bmp, .docx (max10MB) and IWork pages",
            },
            review: {
              title: "Cargada",
            },
            fileTooBigErrorTitle: "EL ARCHIVO ES DEMASIADO PESADO",
            fileTooBigErrorDescription:
              "Tu archivo es demasiado pesado. Considera ###FILE_SIZE###MB máximo",
            changeMethod: "Cambiar el método de envío",
            uploadDifferentFile: "SUBIR UN ARCHIVO DISTINTO",
            prescriptionUploaded: "RECETA CARGADA",
          },
          later: {
            review: {
              title:
                "La solicitaremos después de finalizar el proceso de compra",
            },
          },
        },
        brand: {
          confirmedTitle: "Tipo de micas:",
        },
        treatmentsFamily: {
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included.",
        },
        thickness: {
          title: "SELECCIONA EL GROSOR DE TUS MICAS",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included.",
        },
        treatments: {
          addToBag: "AGREGAR AL CARRITO",
        },
        lensColor: {
          categories: {
            all: {
              title: "Todos",
            },
          },
        },
        color: {
          tooltip: {
            switch:
              "Reduce el resplandor de la luz extremadamente brillante, para una claridad y protección superior de tus ojos.",
            polarized:
              "Reduce el resplandor de la luz extremadamente brillante, para una claridad y protección superior de tus ojos.",
            blueLight:
              "Reduces exposure to blue light from digital screens and sun rays, which can help reduce eye fatigue",
            antiReflective:
              "Reduce los reflejos y proporciona un escudo invisible. Resiste el deslumbramiento, el polvo y las manchas para una visión más clara.",
            uvProtection:
              "Resistentes capas protectoras en la parte delantera y trasera de las micas, para una máxima protección contra los rayos UV y rayones.",
          },
        },
        addOns: {
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant and UV protection are included.",
        },
        review: {
          title: "REVISA TU SELECCIÓN",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology.",
          paymentInstallments: {
            installmentsLabel:
              "Pay over time in interest-free installments with ###PAYMENT_TYPE###",
          },
          seeDetails: "Ve los detalles",
          tooltip:
            "Ensure your eyewear with an extended warranty for unlimited repair and replacement at any LensCrafters store.",
        },
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "PRECIO DEL ARMAZÓN + MICAS:",
        },
      },
      and: "y",
      exitTitle: "¿SEGURO QUE QUIERES SALIR?",
      yourSelections: "Your selections",
    },
    en_AU: {
      steps: {
        advancedPrescription: {
          manual: {
            card: {
              description:
                "This is our quickest and most popular method. We will then recommend the most suitable lens type..",
            },
            whatIsIt: "How to measure your PD",
            alerts: {
              axisRangeError:
                "The AXIS values you’ve inserted are not correct! These values must be between 1 and 180.",
              sphPositiveNegativeWarning:
                "<b>You’ve inserted 1 positive and 1 negative value, which is very rare.</b><br>We suggest double checking your prescription. If this is correct, you can proceed.",
              agreementText:
                "By clicking this box, I confirm that the prescription values entered above are taken from a valid (not expired) prescription issued to me, signed by a licensed optometrist or ophthalmologist.",
            },
            continueModal: {
              title: "Send prescription later",
              message:
                "By clicking on continue, you will be skipping the prescription step. We’ll ask for it after your order, either by uploading it or having us call your doctor.",
            },
          },
          upload: {
            review: {
              title: "Uploaded",
            },
          },
          later: {
            card: {
              description:
                "We'll ask for it after you order, either by uploading it or having us call your doctor.",
            },
          },
        },
        treatmentsFamily: {
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included.",
        },
        thickness: {
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch resistant and UV protection are included.",
        },
        color: {
          confirmedTitle: "Colour:",
          title: "Choose your lens colour",
          polarized: "Polarised only",
          applyAndContinue: "Apply",
          subtitle: "Enhanced colour and contrast so you can see more detail",
          tooltip: {
            switch:
              "Reduces glare in extra bright light, for superior clarity and eye protection.",
            polarized:
              "Reduces glare in extra bright light, for superior clarity and eye protection.",
          },
        },
        addOns: {
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant and UV protection are included.",
        },
        review: {
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology.",
        },
      },
    },
    es_MX: {
      steps: {
        type: {
          title: "Selecciona tus necesidades de visión",
          confirmedTitle: "Tipo de graduación:",
        },
        brand: {
          confirmedTitle: "Tipo de micas:",
        },
        thickness: {
          title: "SELECCIONA EL GROSOR DE TUS MICAS",
          designTypeTitle: "Selecciona el diseño de la mica",
        },
        gvpTreatment: {
          title: "Choose your Great Value Package",
        },
        treatments: {
          addToBag: "AGREGAR AL CARRITO",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          openButtonLabel: "prueba",
        },
        lensColor: {
          categories: {
            all: {
              title: "Todos",
            },
          },
        },
        color: {
          confirmedTitle: "color:",
          tooltip: {
            polarized:
              "Reduce el resplandor de la luz extremadamente brillante, para una claridad y protección superior de tus ojos.",
            switch:
              "Reduce el resplandor de la luz extremadamente brillante, para una claridad y protección superior de tus ojos.",
            antiScratch:
              "Protege la superficie de las lentes de los impactos fuertes ",
            antiReflective:
              "Reduce los reflejos y proporciona un escudo invisible. Resiste el deslumbramiento, el polvo y las manchas para una visión más clara.",
            uvProtection:
              "Resistentes capas protectoras en la parte delantera y trasera de las micas, para una máxima protección contra los rayos UV y rayones.",
          },
        },
        review: {
          title: "REVISA TU SELECCIÓN",
          subtitle:
            "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase.",
          paymentInstallments: {
            installmentsLabel:
              "Paga en cuotas sin intereses con\n###PAYMENT_TYPE###",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          seeDetails: "Ve los detalles",
          tooltip:
            "Asegura tus lentes con una garantía extendida para reparaciones y reemplazos ilimitados en cualquier tienda LensCrafters.",
        },
        advancedPrescription: {
          manual: {
            title: "Ingresa tu receta",
            review: {
              title: "Se ingresan manualmente",
            },
            card: {
              title: "Ingrésala de manera manual",
              description:
                "Este es nuestro método más rápido y popular. A continuación, recomendaremos el tipo de mica más adecuado.",
            },
            subtitle:
              "Agrega tus valores de la receta y te recomendaremos las mejores micas para tus necesidades de visión.",
            continueModal: {
              title: "Envía la receta más tarde",
              message:
                "Al hacer clic en continuar, te saltarás el paso de receta. La solicitaremos después de tu pedido, ya sea cargándola o le hablaremos a tu médico.",
              cancel: "No, regresar",
            },
            clearAll: "Reiniciar",
            incompatibleFrame:
              "Lo sentimos, el armazón que elegiste no es compatible con tu receta. Selecciona otro modelo.\n </br>¿Tienes dudas? <a>Puedes ponerte en contacto con nuestro equipo de Atención a clientes.</a>",
          },
          upload: {
            title: "Tu receta",
            subtitle:
              "Antes de enviar tu receta, verifica la fecha de vencimiento para asegurarte de que aún sea válida.",
            card: {
              title: "Cárgala ahora",
            },
            review: {
              title: "Cargada",
            },
            fileTooBigErrorTitle: "EL ARCHIVO ES DEMASIADO PESADO",
            fileTooBigErrorDescription:
              "Tu archivo es demasiado pesado. Considera ###FILE_SIZE###MB máximo",
            uploadDifferentFile: "SUBIR UN ARCHIVO DISTINTO",
            changeMethod: "Cambiar el método de envío",
            prescriptionUploaded: "RECETA CARGADA",
            somethingWentWrong: "Lo sentimos, hubo un error.",
            confirmAndContinue: "CARGAR",
          },
          doctor: {
            title: "Agrega la información de tu doctor",
            subtitle: "Obtendremos los detalles de tu receta con él/ella",
            searchBy: "Busca a tu oculista por",
            card: {
              title: "Llama a mi doctor",
              description: "Obtendremos tu receta con él/ella",
            },
          },
          later: {
            card: {
              description:
                "La solicitaremos después de tu pedido, ya sea cargándola o le hablaremos a tu médico.",
            },
            review: {
              title:
                "La solicitaremos después de finalizar el proceso de compra",
            },
          },
        },
        treatmentsFamily: {
          subtitle:
            "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase. Se incluyen protección UV y resistencia a los rayones.",
          clear: {
            description:
              "Micas tradicionales y translúcidas, perfectas para el uso diario",
          },
        },
        addOns: {
          confirmedTitle: "Completa tus micas",
          title: "Completa tus micas",
          subtitle:
            "Tus lentes tendrán la mejor tecnología Ray-Ban de su clase. Se incluyen protección UV y resistencia a los rayones.",
        },
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "PRECIO DEL ARMAZÓN + MICAS:",
        },
      },
      and: "y",
      noTreatment: "No treatment",
      exitTitle: "¿SEGURO QUE QUIERES SALIR?",
    },
    de_DE: {
      steps: {
        review: {
          subtitle:
            "Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert.",
          paymentInstallments: {
            installmentsLabel:
              "Ratenzahlung in zinslosen Raten mit ###PAYMENT_TYPE###",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          seeDetails: "Details anzeigen",
          tooltip:
            "Schützen Sie Ihre Brille mit einer erweiterten Garantie für unbegrenzte Reparatur- und Ersatzleistungen in jedem LensCrafters-Geschäft.",
        },
        treatments: {
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          openButtonLabel: "anprobieren",
        },
        thickness: {
          designTypeTitle: "Brillenglasdesign auswählen",
        },
        advancedPrescription: {
          manual: {
            review: {
              title: "Manuell eingegeben",
            },
            addPrismValues: "Prismenwerte hinzufügen",
            title: "Daten aus dem Brillenpass eingeben",
            card: {
              description:
                "Dies ist unsere schnellste und beliebteste Methode. Wir empfehlen Ihnen dann den am besten geeigneten Brillenglastyp.",
            },
            subtitle:
              "Fügen Sie Ihre Korrektionswerte hinzu und wir empfehlen Ihnen das Glas, das am besten für Ihre Sehanforderungen geeignet ist.",
            add: "Hinzufügen",
            iHaveTwoPd: "Ich habe zwei PD",

            continueModal: {
              title: "Brillenpass später senden",
              message:
                "Wenn Sie auf „Weiter“ klicken, überspringen Sie den Schritt „Eingabe der Korrekturwerte“. Vor Abschluss der Bestellung werden wir Sie an die Eingabe der Werte erinnern.",
              cancel: "Nein, zurück",
            },
            incompatibleFrame:
              "Es tut uns leid – das von Ihnen gewählte Modell ist nicht für Ihrer Sehstärke erhältlich. Bitte wählen Sie ein anderes Modell.\n </br>Haben Sie eine Frage? <a>Wenden Sie sich an unser Serviceteam.</a>",
          },
          upload: {
            title: "Ihre Brillenverordnung",
            subtitle:
              "Bevor Sie Ihre Brillenverordnung einreichen, überprüfen Sie bitte das Ablaufdatum, um sicherzustellen, dass sie noch gültig ist.",
            review: {
              title: "HOCHGELADEN",
            },
            prescriptionUploaded: "Brillenverodnung wurde hochgeladen",
            fileTooBigErrorTitle: "Datei ist zu groß",
            fileTooBigErrorDescription:
              "Ihre Datei ist zu groß. Maximaler Upload ###FILE_SIZE### MB",
            changeMethod: "Übermittlungsart ändern",
            uploadDifferentFile: "Eine andere Datei hochladen",
            pdForm: {
              pupillaryDistance: "PD (Pupillendistanz)",
              whatIsIt: "Was ist das?",
              iHaveTwoPd: "Ich habe 2 PD-Nummern",
            },
            confirmAndContinue: "Bestätigen & Fortfahren",
          },
          later: {
            card: {
              description:
                "Vor Abschluss der Bestellung werden wir Sie an die Eingabe der Werte erinnern.",
            },
            review: {
              title: "Wir werden Sie bei Bestellabschluss danach fragen",
            },
          },
        },
        color: {
          tooltip: {
            antiScratch: "Schützt Ihre Glasoberfläche vor harten Stößen",
          },
        },
        treatmentsFamily: {
          subtitle:
            "Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert. Kratzfest und inklusive UV-Schutz.",
        },
        addOns: {
          subtitle:
            "Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert. Kratzfest und inklusive UV-Schutz.",
        },
      },
      frameSize: "Frame size",
    },
    de_CH: {
      steps: {
        advancedPrescription: {
          manual: {
            review: {
              title: "Manuell eingegeben",
            },
            addPrismValues: "Prismenwerte hinzufügen",
            title: "Daten aus dem Brillenpass eingeben",
            card: {
              description:
                "Dies ist unsere schnellste und beliebteste Methode. Wir empfehlen Ihnen dann den am besten geeigneten Brillenglastyp.",
            },
            subtitle:
              "Fügen Sie Ihre Korrektionswerte hinzu und wir empfehlen Ihnen das Glas, das am besten für Ihre Sehanforderungen geeignet ist.",
            iHaveTwoPd: "Ich habe zwei PD",

            continueModal: {
              title: "Brillenpass später senden",
              message:
                "Wenn Sie auf „Weiter“ klicken, überspringen Sie den Schritt „Eingabe der Korrekturwerte“. Vor Abschluss der Bestellung werden wir Sie an die Eingabe der Werte erinnern.",
              cancel: "Nein, zurück",
            },
          },
          upload: {
            title: "Ihre Brillenverordnung",
            subtitle:
              "Bevor Sie Ihre Brillenverordnung einreichen, überprüfen Sie bitte das Ablaufdatum, um sicherzustellen, dass sie noch gültig ist.",
            review: {
              title: "HOCHGELADEN",
            },
            prescriptionUploaded: "Brillenverodnung wurde hochgeladen",
            fileTooBigErrorTitle: "Datei ist zu groß",
            fileTooBigErrorDescription:
              "Ihre Datei ist zu groß. Maximaler Upload ###FILE_SIZE### MB",
            changeMethod: "Übermittlungsart ändern",
            uploadDifferentFile: "Eine andere Datei hochladen",
            pdForm: {
              pupillaryDistance: "PD (Pupillendistanz)",
              whatIsIt: "Was ist das?",
              iHaveTwoPd: "Ich habe 2 PD-Nummern",
            },
            confirmAndContinue: "Bestätigen & Fortfahren",
          },
          later: {
            card: {
              description:
                "Vor Abschluss der Bestellung werden wir Sie an die Eingabe der Werte erinnern.",
            },
            review: {
              title: "Wir werden Sie bei Bestellabschluss danach fragen",
            },
          },
        },
        treatmentsFamily: {
          subtitle:
            "Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert. Kratzfest und inklusive UV-Schutz.",
        },
        thickness: {
          designTypeTitle: "Brillenglasdesign auswählen",
        },
        treatments: {
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
        },
        color: {
          tooltip: {
            uvProtection:
              "Harte Schutzschichten auf Vorder- und Rückseite der Gläser für maximalen Schutz vor UV-Strahlung und Kratzern.",
            antiReflective:
              "Reduzieren Spiegelungen und dienen als unsichtbarer Schutz. Sie sind blendfrei, staub- und schmutzabweisend und sorgen für klare Sicht.",
            antiScratch: "Schützt Ihre Glasoberfläche vor harten Stößen",
          },
        },
        addOns: {
          subtitle:
            "Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert. Kratzfest und inklusive UV-Schutz.",
        },
        review: {
          subtitle:
            "Ihre Brille wird nach dem besten Technologiestandard von Ray-Ban veredelt und geliefert.",
          paymentInstallments: {
            installmentsLabel:
              "Ratenzahlung in zinslosen Raten mit ###PAYMENT_TYPE###",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          seeDetails: "Details anzeigen",
          tooltip:
            "Schützen Sie Ihre Brille mit einer erweiterten Garantie für unbegrenzte Reparatur- und Ersatzleistungen in jedem LensCrafters-Geschäft.",
        },
      },
      frameSize: "Frame size",
    },
    it_IT: {
      steps: {
        advancedPrescription: {
          manual: {
            addPrismValues: "Aggiungi valori prismatici",
            review: {
              title: "Valore inserito manualmente",
            },

            incompatibleFrame:
              "Spiacenti, la montatura scelta non è compatibile con la ricetta. Si prega di scegliere un altro modello.\n </br>Domande? <a>Contatta il nostro Servizio Clienti.</a>",
          },
          upload: {
            review: {
              title: "CARICATO",
            },
          },
          doctor: {
            card: {
              description:
                "Chiederemo la tua prescrizione medica direttamente a lei/lui.",
            },
          },
          later: {
            card: {
              description:
                "Più avanti nel corso dell’evasione del tuo ordine ti verrà richiesto di caricarla o di metterci in contatto con il tuo medico.",
            },
            review: {
              title: "Ti chiederemo il documento dopo il pagamento",
            },
          },
        },
        treatmentsFamily: {
          subtitle:
            "I tuoi occhiali vanteranno la miglior tecnologia Ray-Ban. Protezione UV e trattamento antigraffio inclusi.",
        },
        treatments: {
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
        },
        color: {
          tooltip: {
            antiScratch: "Protegge la superficie delle lenti dagli urti",
          },
        },
        addOns: {
          subtitle:
            "I tuoi occhiali vanteranno la miglior tecnologia Ray-Ban. Protezione UV e trattamento antigraffio inclusi",
        },
        review: {
          subtitle: "I tuoi occhiali vanteranno la miglior tecnologia Ray-Ban.",
          paymentInstallments: {
            installmentsLabel:
              "Paga a rate a tasso zero con ###PAYMENT_TYPE###",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          seeDetails: "Vedi i dettagli",
          tooltip:
            "Assicura i tuoi occhiali con una estensione di garanzia: comprenderà riparazioni senza alcun limite e sostituzione in qualunque negozio LensCrafters.",
        },
      },
      price: {
        totalFrameLens: {
          total: "Total",
        },
      },
      insuranceSyncedLabel: "Insurance is synced",
      frameSize: "Frame size",
    },
    it_CH: {
      steps: {
        color: {
          tooltip: {
            antiReflective:
              "Riduce i riflessi e offre uno stato protettivo invisibile.  Contrasta l'abbagliamento, la polvere e le macchie, garantendo una visione più chiara.",
            uvProtection:
              "Strati protettivi rigidi sulla parte anteriore e posteriore delle lenti per un'elevata protezione dai raggi UV e una maggiore resistenza ai graffi.",
          },
        },
      },
    },
    fr_BE: {
      steps: {
        prescription: {
          title: "Complétez votre ordonnance",
        },
        advancedPrescription: {
          manual: {
            addPrismValues: "Ajouter les valeurs de prisme",
          },
        },
        type: {
          title: "Sélectionnez vos exigences de vue",
          confirmedTitle: "Utilisez",
        },
        brand: {
          title: "Choisissez la marque de vos verres",
        },
        thickness: {
          title: "Sélectionnez l’épaisseur de vos verres",
        },
      },
    },
    fr_FR: {
      steps: {
        prescription: {
          title: "Complétez votre ordonnance",
        },
        advancedPrescription: {
          manual: {
            review: {
              title: "Saisie manuelle",
            },
            title: "Saisissez votre correction",
            card: {
              description:
                "Il s'agit de notre méthode la plus rapide et la plus populaire. Nous vous recommanderons ensuite le type de verres le plus adapté.",
            },
            subtitle:
              "Ajoutez les valeurs de votre ordonnance et nous vous recommanderons les verres les mieux adaptés à votre vue.",

            continueModal: {
              message:
                "En cliquant sur continuer, vous sauterez l'étape de l'ordonnance. Nous vous la demanderons après votre commande, soit en la téléchargeant, soit en nous demandant d'appeler votre médecin.",
              cancel: "Non, retourner en arrière",
            },
            incompatibleFrame:
              "Nous sommes désolés, la monture que vous avez choisie n'est pas compatible avec votre ordonnance. Merci de sélectionner un autre modèle.\n </br>Vous avez des questions ? <a>Vous pouvez contacter notre service clientèle.</a>",
          },
          upload: {
            subtitle:
              "Avant d’envoyer votre ordonnance, vérifier la date d'expiration pour vous assurer qu'elle est toujours valable.",
            review: {
              title: "TÉLÉCHARGÉE",
            },
            alerts: {
              agreementText:
                "En cochant cette case, je confirme que les valeurs de correction saisies ci-dessus sont tirées d'une ordonnance en cours de validité (non expirée) qui m'a été délivrée, signée par un opticien ou un ophtalmologiste agréé.",
            }
          },
          later: {
            card: {
              description:
                "Nous vous la demanderons après votre commande, soit en la téléchargeant, soit en nous demandant d'appeler votre médecin.",
            },
            review: {
              title: "Nous vous la demanderons après le paiement",
            },
          },
        },
        brand: {
          confirmedTitle: "Marque",
        },
        treatmentsFamily: {
          subtitle:
            "Vos lunettes seront équipées de la meilleure technologie Ray-Ban. Une protection contre les rayures et les UV est incluse.",
        },
        thickness: {
          confirmedTitle: "Épaisseur",
          title: "Sélectionnez l’épaisseur de vos verres",
        },
        treatments: {
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          openButtonLabel: "essayer",
        },
        color: {
          tooltip: {
            antiScratch:
              "Protège la surface de vos verres contre les chocs violents",
          },
        },
        addOns: {
          subtitle:
            "Vos lunettes seront équipées de la meilleure technologie Ray-Ban. Une protection contre les rayures et les UV est incluse",
        },
        review: {
          subtitle:
            "Vos lunettes seront équipées de la meilleure technologie Ray-Ban.",
          paymentInstallments: {
            installmentsLabel:
              "Payez en plusieurs versements sans intérêt avec ###PAYMENT_TYPE###",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          insuranceButtonLabel: "Appliquer l’assurance",
          seeDetails: "Voir les détails",
          tooltip:
            "Assurez vos lunettes avec une garantie étendue pour une réparation et un remplacement sans limitations dans n'importe quel magasin LensCrafters.",
        },
      },
      prescription: {
        toggleLabel:
          "Ajoutez les valeurs de votre ordonnance, nous vous aiderons à trouver la paire parfaite pour vos besoins",
        prescriptionTitle:
          "Ajoutez les valeurs de votre ordonnance, nous vous montrerons le verre répondant parfaitement à vos besoins.",
        readPrescription: "Comment lire votre ordonnance",
        addLabel: "J’ai aussi une valeur d’addition",
        applyButton: "Appliquer et continuer",
        submitButton: "Envoyer",
        clearButton: "Effacer tout",
        skipButton: "Ignorer pour l’instant",
        compatibleFramesButton: "Acheter des montures compatibles",
        doctorDetails:
          "Nous vous demanderons une copie de votre ordonnance ou les coordonnées de votre médecin pendant ou après le paiement",
        missingValueError: "Ajoutez vos valeurs pour continuer",
        positiveNegativeError:
          "Vous avez entré une valeur positive et une valeur négative, ce qui est très rare. Nous vous conseillons de vérifier votre ordonnance.",
        compatibleFrameError:
          "Désolés ! La monture que vous avez choisie n’est pas compatible avec les valeurs de votre correction.",
        axisError: "Les valeurs d’AXE que vous avez saisies sont incorrectes !",
        axisSubError: "Ces valeurs doivent entre 0 et 180.",
        axisSubErrorZero:
          "Si la valeur CYL est supérieure ou inférieure à 0, les valeurs d’AXE ne peuvent pas être 0.",
        editLabel: "Modifier la correction",
        twoPDNumbers: "J’ai deux valeurs d'EP",
        missingInputMessage:
          "Ajoutez les valeurs manquantes pour compléter le téléchargement de votre ordonnance et nous vous recommanderons le verre le plus adapté.",
        defaultInputMessage:
          "Ajoutez vos valeurs SPH et CYL et nous vous recommanderons le verre le plus adapté aux besoins de votre vue.",
        skipMessage:
          "Nous vous demanderons de compléter ou de télécharger votre ordonnance plus tard",
        readyMessage:
          "Nous sommes maintenant en mesure de vous proposer le verre qui vous convient le mieux  !",
        bestPrescription: "Le meilleur pour votre correction",
        proceedCheckbox: "Obligatoire pour continuer",
        proceedCheckboxError: "Ou {0} paiements de {1}{2} avec",
      },
      price: {
        startingAt: "Prix partant de",
      },
      learnMoreMade: "Voir comment c’est fait",
      learnMoreTreatment: "Voir le traitement",
      exitTitle: "ÊTES-VOUS SÛR DE VOULOIR SORTIR ?",
      exitSubtitle: "Votre choix de verres ne sera pas enregistré",
      exitContinueEditing: "Continuer la modification",
      exitSave: "Enregistrer et continuer le shopping",
      insuranceButtonLabel: "Ajouter des prestations sociales",
      insuranceSyncedLabel: "Prestations sociales appliquées",
      applyingInsurance: "Application des prestations sociales en cours",
      frameSize: "Frame size",
    },
    fr_CH: {
      steps: {
        advancedPrescription: {
          manual: {
            addPrismValues: "Ajouter les valeurs de prisme",
          },
        },
        brand: {
          confirmedTitle: "Marque",
        },
        color: {
          tooltip: {
            antiReflective:
              "Réduit les reflets et fournit une protection visible. Résiste à l'éblouissement, à la poussière et aux taches pour une vision plus claire.",
            uvProtection:
              "Couches de protection dures sur l’avant et l’arrière des verres pour protéger totalement des UV et des rayures.",
          },
        },
      },
      insuranceSyncedLabel: "Prestations sociales appliquées",
    },
    nl_NL: {
      steps: {
        prescription: {
          title: "Rond jouw brilrecept af",
        },
        advancedPrescription: {
          manual: {
            review: {
              title: "Handmatig ingevoerd",
            },
            addPrismValues: "Prismawaarden toevoegen",
            title: "Voer je brilrecept in",
            card: {
              title: "Voer het met de hand in",
              description:
                "Dit is de snelste en meest gebruikte methode. Daarna adviseren we je het meest geschikte type brillenglas.",
            },
            subtitle:
              "Voeg je brilrecept toe en we zullen je het brillenglas aanbevelen dat het meest geschikt is voor jouw sterkte.",

            continueModal: {
              title: "Stuur het brilrecept later op",
              message:
                "Door op verder te klikken, sla je de stap betreffende het brilrecept over. We zullen je na de bestelling om het brilrecept vragen dat je vervolgens kunt uploaden, of dat we telefonisch direct bij jouw arts kunnen opvragen.",
              cancel: "Nee, terug",
            },
            incompatibleFrame:
              "Helaas, het door jou gekozen montuur is niet compatibel met je brilrecept. Selecteer een ander model.\n </br>Heb je een vraag? <a>Neem dan contact op met onze klantenservice.</a>",
          },
          upload: {
            subtitle:
              "Controleer de geldigheidsdatum om na te gaan of je brilrecept nog altijd geldig is, voordat je het indient.",
            card: {
              title: "Upload het nu",
            },
            review: {
              title: "GEÜPLOAD",
            },
          },
          later: {
            card: {
              title: "Stuur het later op",
              description:
                "We zullen je na de bestelling om het brilrecept vragen dat je vervolgens kunt uploaden, of dat we telefonisch direct bij jouw arts kunnen opvragen.",
            },
            review: {
              title: "We zullen hier na het afrekenen om vragen",
            },
          },
        },
        type: {
          title: "Selecteer de benodigde sterkte",
          confirmedTitle: "Gebruik",
        },
        brand: {
          title: "Kies het merk brillenglazen",
          confirmedTitle: "Merk",
        },
        treatmentsFamily: {
          subtitle:
            "Jouw bril wordt geleverd met de beste technologie van Ray-Ban. Inclusief krasbestendigheid en UV-bescherming.",
        },
        gvpTreatment: {
          title: "Rond jouw voordeelpakket af",
        },
        thickness: {
          confirmedTitle: "Dikte",
          title: "Selecteer de dikte van de brillenglazen",
        },
        treatments: {
          addToBag: "Aan boodschappenmand toevoegen",
          title: "Rond jouw brillenglas af",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Behandelingen",
          extraTreatments: {
            antiScratch: {
              title: "Krasbestendig",
            },
            antiReflective: {
              value: "Antireflexbehandeling",
            },
            uvProtection: {
              title: "UV-bescherming en krasbestendig",
            },
          },
          openButtonLabel: "proberen",
        },
        color: {
          tooltip: {
            uvProtection:
              "Harde beschermende coatings aan de voor- en achterkant van de glazen voor maximale uv- en krasbescherming.",
            antiReflective:
              "Vermindert reflecties en zorgt voor een onzichtbaar schild. De coatings zijn bestand tegen verblinding, stof en vlekken, voor een helderder zicht.",
            antiScratch: "Beschermt je brillenglazen tegen harde schokken",
          },
        },
        addOns: {
          subtitle:
            "Jouw bril wordt geleverd met de beste technologie van Ray-Ban. Inclusief krasbestendigheid en UV-bescherming",
        },
        review: {
          subtitle:
            "Jouw bril wordt geleverd met de beste technologie van Ray-Ban.",
          paymentInstallments: {
            installmentsLabel:
              "Gespreide betaling met rentevrije afbetalingstermijnen met ###PAYMENT_TYPE###",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          seeDetails: "Zie details",
          tooltip:
            "Verzeker je bril met een verlengde garantie voor onbeperkte reparatie en vervanging in elke LensCrafters-winkel.",
        },
      },
      prescription: {
        toggleLabel:
          "Voeg de gegevens van jouw brilrecept in, we helpen je het juiste brillenglas voor jouw sterkte te vinden.",
        prescriptionTitle:
          "Voeg de gegevens van jouw brilrecept in, we tonen je het juiste brillenglas voor jouw sterkte.",
        readPrescription: "Hoe lees je het brilrecept",
        addLabel: "Ik heb ook een ADD-waarde",
        applyButton: "Toepassen en doorgaan",
        submitButton: "Verzenden",
        clearButton: "Alle wissen",
        skipButton: "Tijdelijk overslaan",
        compatibleFramesButton: "Koop compatibele monturen",
        doctorDetails:
          "Na het afrekenen vragen we je om een kopie van jouw brilrecept of de gegevens van jouw oogarts",
        missingValueError: "Voeg jouw gegevens toe om door te gaan",
        positiveNegativeError:
          "Je hebt één positieve waarde en één negatieve waarde ingevoerd. Dit is bijzonder zeldzaam. Ik adviseer je om het brilrecept te controleren.",
        compatibleFrameError:
          "Helaas! Het montuur dat je hebt gekozen is niet geschikt voor jouw brilrecept.",
        compatibleFrameSubLabel:
          "Geen probleem. We hebben monturen die voor jouw brilrecept geschikt zijn.",
        axisError: "De AS-waarden die je hebt ingevoerd zijn niet juist!",
        axisSubError: "Deze moeten tussen 0 en 180 liggen.",
        axisSubErrorZero:
          "Als de CYL-waarde hoger of lager is dan 0, dan kunnen de AS-waarden niet 0 zijn.",
        editLabel: "Brilrecept bewerken",
        pdHelp: "Wat is dit?",
        twoPDNumbers: "Ik heb twee PD-nummers",
        missingInputMessage:
          "Voeg de ontbrekende gegevens in zodat je het uploaden van het brilrecept kunt afronden en we zullen je het meest geschikte brillenglas aanbevelen.",
        defaultInputMessage:
          "Voeg jouw SPH- en CYL-waarden in en we zullen je het brillenglas aanbevelen dat het meest geschikt is voor jouw sterkte.",
        skipMessage:
          "We vragen later om het brilrecept af te ronden of te uploaden",
        readyMessage: "Nu kunnen we je het beste brillenglas aanbevelen!",
        bestPrescription: "Het beste voor jouw brilrecept",
        proceedCheckbox: "Vereist om door te gaan",
        proceedCheckboxError: "Of {0} betalingen van {1}{2} met",
      },
      price: {
        startingAt: "Vanaf",
      },
      learnMoreMade: "Zie hoe hij wordt gemaakt",
      learnMoreTreatment: "Bekijk de behandeling",
      exitTitle: "WEET JE HET ZEKER DAT JE WILT AFSLUITEN?",
      exitSubtitle: "De gekozen brillenglazen worden niet opgeslagen",
      exitContinueEditing: "Doorgaan met bewerken",
      exitSave: "Opslaan en verder winkelen",
      insuranceButtonLabel: "Verzekeringsuitkeringen toevoegen",
      insuranceSyncedLabel: "Verzekeringsuitkeringen toegepast",
      applyingInsurance: "Verzekeringsuitkeringen worden toegepast",
      frameSize: "Frame size",
    },
    nl_BE: {},
    pt_BR: {
      steps: {
        treatmentsFamily: {
          subtitle:
            "Seus óculos vêm com a melhor tecnologia Ray-Ban. Resistentes a riscos e proteção UV incluídos.",
        },
        treatments: {
          subtitle:
            "Seus óculos vêm com a melhor tecnologia Ray-Ban. Tratamento resistente a riscos e proteção UV incluídos.",
          openButtonLabel: "experimente",
        },
        addOns: {
          subtitle:
            "Seus óculos vêm com a melhor tecnologia Ray-Ban. Resistentes a riscos e proteção UV incluídos.",
        },
        review: {
          subtitle: "Seus óculos vêm com a melhor tecnologia Ray-Ban.",
        },
        advancedPrescription: {
          manual: {
            incompatibleFrame:
              "Lamentamos, mas a armação que escolheu não é compatível com a sua graduação. Selecione outro modelo.\n </br>Tem alguma questão? <a>Pode contactar a nossa equipa de Apoio ao Cliente.</a>",
            alerts: {
              agreementText:
                "Confirmo que os valores de prescrição inseridos são de uma prescrição válida (não vencida) emitida para mim, dentro de um período de 1 ano e assinada por um médico oftalmologista licenciado.",
            },
          },
        },
      },
    },
    no_NO: {
      steps: {
        advancedPrescription: {
          manual: {
            card: {
              description:
                "Dette er vår raskeste og mest populære metode. Vi vil da anbefale det brilleglasset som passer best.",
            },
            pupillaryDistanceSubtitle:
              'Du kan velge standardinnstillingene <b>61 for kvinner</b> og <b>64 for menn</b> hvis du bruker en gjennomsnittlig eller liten styrke. Hvis du bruker stor styrke (eller vil vite nøyaktig pupillavstand), må du <a  data-element-id="X_X_LensPanel_AdvancedPrescription-MeasurePD">måle pupillavstanden din</a>',
            alerts: {
              missingBaseDir: "Velg grunnretningen for prismet ditt",
            },
            continueModal: {
              cancel: "Nei, gå tilbake",
            },
            digitalOptometry: {
              appSubtitle:
                "Pupillavstanden er avstanden i millimeter (mm) fra midten av en pupill til midten av den andre. Den angir nøyaktig hvilken del av brilleglasset du ser gjennom, og sikrer optimal komfort og klarhet. Du kan måle med appen eller manuelt.",
            },
          },
          upload: {
            prescriptionUploaded: "Brilleseddel lastet opp",
          },
          later: {
            card: {
              description:
                "Vi vil be om det etter bestillingen din, enten ved å laste den opp eller ved at vi ringer legen din.",
            },
          },
        },
        treatmentsFamily: {
          subtitle:
            "Brillene dine vil komme med førsteklasses Ray-Ban-teknologi. Ripebestandig og UV-beskyttelse er inkludert.",
        },
        treatments: {
          subtitle:
            "Brillene dine vil komme med førsteklasses Ray-Ban-teknologi. Ripebestandig behandling og UV-beskyttelse er inkludert.",
        },
        color: {
          tooltip: {
            uvProtection:
              "Harde beskyttende lag foran og bak på brilleglassene for maksimal UV- og ripebeskyttelse.",
          },
        },
        addOns: {
          subtitle:
            "Brillene dine vil komme med førsteklasses Ray-Ban-teknologi. Ripebestandig og UV-beskyttelse er inkludert.",
        },
        review: {
          subtitle:
            "Brillene dine vil komme med førsteklasses Ray-Ban-teknologi.",
        },
      },
      frameSize: "Innfatningsstørelse",
    },
    sv_SE: {
      exitYes: "Ja, lämna",
      steps: {
        prescription: {
          title: "Slutför ditt glasögonrecept",
        },
        advancedPrescription: {
          manual: {
            review: {
              title: "Matas in manuellt",
            },
            addPrismValues: "Lägg till prismavärden",

            incompatibleFrame:
              "Tyvärr – bågen som du har valt är inte kompatibel med ditt glasögonrecept. Välj en annan modell.\n </br>Har du frågor? <a>Du kan kontakta vårt kundtjänstteam.</a>",
          },
          upload: {
            review: {
              title: "UPPLADDAT",
            },
          },
          later: {
            review: {
              title: "Vi ber om det efter utcheckning",
            },
          },
        },
        type: {
          title: "Välj dina synbehov",
        },
        brand: {
          title: "Välj ditt linsmärke",
          confirmedTitle: "Varumärke",
        },
        treatmentsFamily: {
          subtitle:
            "Dina glasögon har förstklassig Ray-Ban-teknik. Reptålighet och UV-skydd ingår.",
        },
        gvpTreatment: {
          title: "Slutför ditt Prisvärda paket",
        },
        thickness: {
          confirmedTitle: "Tjocklek",
          title: "Välj din linstjocklek",
        },
        treatments: {
          addToBag: "Lägg till i varukorg",
          title: "Slutför din lins",
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          confirmedTitle: "Behandlingar",
          extraTreatments: {
            antiScratch: {
              title: "Reptålighet",
            },
            antiReflective: {
              value: "Antireflektionsbehandling",
            },
            uvProtection: {
              title: "UV-skydd och reptålighet",
            },
          },
          openButtonLabel: "prova",
        },
        color: {
          tooltip: {
            antiScratch: "Skyddar ytan på din lins mot kraftig yttre påverkan.",
          },
        },
        addOns: {
          subtitle:
            "Dina glasögon har förstklassig Ray-Ban-teknik. Reptålighet och UV-skydd ingår",
        },
        review: {
          subtitle: "Dina glasögon har förstklassig Ray-Ban-teknik.",
          paymentInstallments: {
            installmentsLabel:
              "Betala över tid genom räntefria avbetalningar med ###PAYMENT_TYPE###",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          insuranceButtonLabel: "Applicera försäkring",
          seeDetails: "Se detaljer",
          tooltip:
            "Försäkra dina glasögon med en utökad garanti för obegränsad reparation och utbyte hos valfri LensCrafters-butik.",
        },
      },
      prescription: {
        toggleLabel:
          "Lägg till dina glasögonreceptvärden, så hjälper vi dig att hitta den perfekta linsen för dina synbehov.",
        prescriptionTitle:
          "Lägg till dina glasögonreceptvärden, så visar vi dig den perfekta linsen för dina synbehov.",
        readPrescription: "Hur du läser ditt glasögonrecept",
        addLabel: "Jag har även ett ADD-värde",
        applyButton: "Applicera och fortsätt",
        submitButton: "Skicka",
        clearButton: "Rensa alla",
        skipButton: "Hoppa över tills vidare",
        compatibleFramesButton: "Köp kompatibla bågar",
        doctorDetails:
          "Vi kommer att be om en kopia på ditt glasögonrecept eller kontaktuppgifterna till din optiker under eller efter utcheckningen.",
        missingValueError: "Lägg till dina värden för att fortsätta",
        positiveNegativeError:
          "Du skrev in ett positivt värde och ett negativt värde, vilket är ganska sällsynt. Vi rekommenderar att du dubbelkollar ditt glasögonrecept.",
        compatibleFrameError:
          "Tyvärr! Bågen som du har valt fungerar inte med dina glasögonreceptvärden.",
        compatibleFrameSubLabel:
          "Inga problem, vi har bågar som passar ditt glasögonrecept.",
        axisError: "AXIS-värdena som du har angett är inte korrekta!",
        axisSubError: "Dessa värden måste vara mellan 0 och 180.",
        axisSubErrorZero:
          "Om CYL-värdet är högre eller lägre än 0, kan AXIS-värdena inte vara 0.",
        editLabel: "Redigera glasögonrecept",
        pdHelp: "Vad är detta?",
        twoPDNumbers: "Jag har två PD-nummer.",
        missingInputMessage:
          "Lägg till de av dina värden som saknas för att slutföra uppladdningen av ditt glasögonrecept så kommer vi att rekommendera den lins som är mest lämplig.",
        defaultInputMessage:
          "Lägg till dina SPH- och CYL-värden så kommer vi att rekommendera den lins som är mest lämplig för dina synbehov.",
        skipMessage:
          "Vi kommer att be dig slutföra eller ladda upp ditt glasögonrecept senare.",
        readyMessage: "Vi kan inte föreslå den rätta linsen för dig!",
        bestPrescription: "Bäst för ditt glasögonrecept",
        proceedCheckbox: "Du måste fortsätta",
        proceedCheckboxError: "Eller {0} betalningar av {1}{2} med",
      },
      price: {
        startingAt: "Lägsta pris",
      },
      learnMoreMade: "Se hur den görs",
      learnMoreTreatment: "Se behandlingen",
      insuranceButtonLabel: "Lägg till försäkringsförmåner",
      insuranceSyncedLabel: "Försäkringsförmåner applicerade",
      applyingInsurance: "Applicera försäkringsförmåner",
      frameSize: "Frame size",
      yourSelections: "Dina Val",
    },
    ja_JP: {
      steps: {
        advancedPrescription: {
          title: "処方せんをお持ちですか？",
          manual: {
            title: "処方せんを送信",
            card: {
              title: "マニュアル入力",
              description:
                "最も早く最も人気の方法です。これによって最適なレンズタイプをお勧めできます。",
            },
            subtitle:
              "処方せん数値を追加し、お客様が必要とする最適なレンズをお勧めします。",
            sphere: "Sphere（スフィア） (SPH)",
            cylinder: "Cylinder（シリンダー） (CYL)",
            alerts: {
              sphPositiveNegativeWarning:
                "プラス値1つとマイナス値1つを入力されましたが、これは非常に稀なケースです。処方せんをもう一度確認するようにお勧めいたします。もしこれが正確な値の場合、次に進んでください。",
              missingBaseDir: "プリズムの基底方向をお選びください",
              incompatibleWithPrescriptionTitle:
                "ご入力いただいた度数では製作できません。恐れ入りますが、別のフレームをご検討下さい",
              agreementText:
                "✓私（注文者）は、上記入力された情報が、医療機関発行の有効な処方箋に基づいた数値であることを確認しました。",
            },
            continueModal: {
              title: "処方せんを後ほど送信する",
              message:
                "先に進むをクリックすることで、処方せんのステップを飛ばすことができます。ご注文後に書類をアップロードするか、主治医からお電話していただくようにお願いします。",
              continue: "了解して、先に進みます",
              cancel: "元に戻ります",
            },
            manualPreviewTitle: "処方箋の詳細",
            incompatibleFrame:
              "大変申し訳ありません。選択したフレームはお客様の処方せん内容に適しません。別のスタイルを選択してください。\n </br>ご質問は、<a>当社のカスタマーサービス担当までご連絡ください。</a>",
          },
          upload: {
            title: "お客様の処方せん",
            subtitle:
              "処方せんを送信する前に、有効期限内かをもう一度確認してください。",
            card: {
              title: "今すぐアップロードする",
              description:
                "残りは全て当社にお任せください。ご利用いただけるファイル形式は次の通りです：.pdf、 .png、 .jpeg、 .gif、 .tiff、 .bmp、 .docx （最大10MB）、IWork pages",
            },
            uploadDifferentFile: "別のファイルをアップロードする",
            changeMethod: "送信方法を変更する",
            confirmAndContinue: "確定して進む",
          },
          later: {
            card: {
              title: "後ほど送信",
              description:
                "ご注文後に書類をアップロードするか、主治医からお電話していただくようにお願いします。",
            },
          },
        },
        type: {
          confirmedTitle: "レンズの補正機能s",
        },
        treatmentsFamily: {
          title: "ご自分のレンズタイプを選択",
          subtitle:
            "お客様のアイグラスは最高ランクのRay-Ban技術を使っています。スクラッチ耐性と紫外線保護が含まれています。",
        },
        thickness: {
          designTypeTitle: "ご自分のレンズデザインを選択",
        },
        treatments: {
          subtitle:
            "Your glasses will come with best in class Ray-Ban technology. Scratch-resistant treatment  and UV protection are included.",
          openButtonLabel: "試着する",
          extraTreatments: {
            antiScratch: {
              title: "Scratch resistant",
            },
            uvProtection: {
              title: "UV Protection",
            },
          },
        },
        lensColor: {
          confirmedTitle: "レンズテクノロジー＆カラー",
          detailBox: {
            lightTransmission: "可視光線透過率",
            lightConditions: "日照状況",
          },
        },
        color: {
          confirmedTitle: "レンズテクノロジー＆カラー",
          titleMobile: "レンズカラーを選択",
          tooltip: {
            antiScratch: "レンズ表面を強い衝撃から守ります ",
          },
        },
        addOns: {
          title: "レンズに追加するには",
          subtitle:
            "お客様のアイグラスは最高ランクのRay-Ban技術を使っています。スクラッチ耐性と紫外線保護が含まれています",
        },
        review: {
          subtitle:
            "お客様のアイグラスは最高ランクのRay-Ban技術を使っています。",
          paymentInstallments: {
            installmentsLabel: "###PAYMENT_TYPE###で無利子の分割払い",
            modal: {
              title: "Shop now. Pay later.",
              subtitle:
                "Select ###PAYMENT_TYPE### as your payment method at checkout to pay in interest-free installments, with no hidden fees.",
              affirm:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              afterpay:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              klarna:
                "With ###PAYMENT_TYPE###, make ###INSTALLMENTS### interest-free installments of ###MONTHLY_PRICE###",
              footer:
                "You must be over 18, a resident of the U.S., and meet additional eligibility criteria to qualify. Late fees may apply. Loans to California residents made or arranged are pursuant to a California Finance Lenders Law license.",
              exitButton: "Got it!",
            },
          },
          tooltip:
            "アイウェアの延長保証は、全てのLensCrafters ストアで無制限の修理や交換を受けられます。",
        },
      },
      prescription: {
        applyButton: "Apply & continue",
      },
      price: {
        totalFrameLens: {
          frameLensTotal: "Frame + lenses price",
        },
      },
      exitYes: "Yes, exit",
      frameSize: "Frame size",
    },
  },
};
