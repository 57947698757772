import { groupBy, maxBy, orderBy } from "lodash";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { insuranceActions } from "../../../../redux/slices/insurance";
import { configActions } from "../../../../redux/slices/config";
import {
  checkGVP,
  checkLayoutConfigDesignType,
  getCheapPackageFromList,
  getFrameOnlyKey,
  getStepSelections,
  hasValidPrescription,
} from "../../../ConfigLoader";
import {
  useCurrentBrand,
  useCurrentPackages,
  useCurrentType,
  useEnableBrandLastStep,
  useStepAlias,
} from "@hooks";
import { workflow } from "../../../models/Workflow";
import { RIAAPICall, RIAAPICallType } from "../core/types/RIAAPICall";
import { DiscountMethod, RIAManager } from "../RIAManager";
import { getInsuranceParams } from "../getInsuranceParams";
import { InsuranceSettings } from "../core/types/InsuranceSettings";
import { computeRIAFlowTree } from "./utils";

export const useRIA = () => {
  const loadPreselectEnabled = useSelector(
    (state: any) => state.workflow?.loadPreselect
  );
  const currentStep = useSelector((state: any) => state.workflow?.currentStep);
  const lensesDataContent = useSelector(
    (state: any) => state.config?.lensesData?.content
  );
  const configContent = useSelector((state: any) => state.config);
  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );
  const configPackages = useSelector(
    (state: any) => state.config?.lensesData?.packages
  );
  const currentPrescription = useSelector(
    (state: any) => state.prescription?.currentPrescription
  );
  const cartMode = useSelector((state: any) => state.config?.cartMode);
  const RIAConfigBaseUrl = useSelector(
    (state: any) => state.config?.baseURLs?.RIAConfig
  );
  const ecomInsuranceFunction = useSelector(
    (state: any) => state?.config?.insuranceModule?.getInsuranceSettings
  );
  const isLensAdvisorOpened = useSelector(
    (state: any) => state.workflow?.isLensAdvisorOpened
  );
  const frameType = useCurrentType();

  const currentBrand = useCurrentBrand();
  const currentPackages = useCurrentPackages();
  const getAliasStep = useStepAlias();
  const dispatch = useDispatch();
  const enableBrandLastStep = useEnableBrandLastStep();
  const isNestedTransitionSwatchEnabled = useSelector(
    (state: any) => state.config.layoutSettings?.enableNestedTransitionSwatch
  );

  const [stepChains, setStepChains] = useState(null);
  const [RIAParams, setRIAParams] = useState<InsuranceSettings | null>(null);

  useEffect(() => {
    if (
      frameType &&
      currentStep &&
      configPackages &&
      configPackages.length &&
      currentBrand &&
      !stepChains
    ) {
      let isEyeglasses = frameType.toUpperCase() === "EYEGLASSES";
      let isGVP = checkGVP(configPackages);
      let isV5 = configPackages.some(
        (pkg) =>
          pkg.lensPackage.treatmentFamily &&
          pkg.lensPackage.treatmentFamily.trim() !== ""
      );

      if (checkLayoutConfigDesignType(configContent)) {
        if (isEyeglasses) {
          if (isV5) {
            if (!enableBrandLastStep) {
              setStepChains(configContent.RIAFlowConfiguration.eyeglassesV5DT);
            } else {
              setStepChains(
                configContent.RIAFlowConfiguration.eyeglassesV5DTBrandLastStep
              );
            }
          } else {
            setStepChains(configContent.RIAFlowConfiguration.eyeglassesDT);
          }
        } else {
          setStepChains(configContent.RIAFlowConfiguration.sunglassesDT);
        }
      } else {
        if (isEyeglasses) {
          if (isGVP) {
            setStepChains(configContent.RIAFlowConfiguration.eyeglassesGVP);
          } else {
            if (isV5) {
              if (isNestedTransitionSwatchEnabled) {
                setStepChains(
                  configContent.RIAFlowConfiguration
                    .eyeglassesV5NestedTransition
                );
              } else {
                setStepChains(configContent.RIAFlowConfiguration.eyeglassesV5);
              }
            } else {
              setStepChains(configContent.RIAFlowConfiguration.eyeglasses);
            }
          }
          if (
            typeof currentStep?.params?.[0]?.value !== "object" &&
            typeof currentStep?.params?.[0]?.value !== "boolean" &&
            currentStep?.params?.[0]?.value?.toLowerCase() === "frame only"
          ) {
            setStepChains(configContent.RIAFlowConfiguration.eyeFrameOnly);
          }
        } else {
          if (currentStep?.params[0]?.value?.toLowerCase() === "frame only") {
            setStepChains(configContent.RIAFlowConfiguration.sunFrameOnly);
          }
          if (isGVP) {
            setStepChains(configContent.RIAFlowConfiguration.sunglassesGVP);
          }
          if (enableBrandLastStep) {
            setStepChains(
              configContent.RIAFlowConfiguration.sunglassesBrandLastStep
            );
          } else {
            setStepChains(configContent.RIAFlowConfiguration.sunglasses);
          }
        }
      }
    }
  }, [
    frameType,
    configPackages,
    currentBrand,
    currentStep,
    isNestedTransitionSwatchEnabled,
  ]);

  const pruneFlowTreeAtCurrentStep = (
    flowTree: any[],
    frameOnlyKey: string
  ) => {
    let stepSelections = getStepSelections(
      getAliasStep,
      true,
      lensesDataContent
    )
      .filter((sel) => sel.originalStep !== "AdvancedPrescription")
      .map((sel) => {
        if (
          sel.originalStep !== "TreatmentsFamily" &&
          sel.originalStep !== "Treatments"
        ) {
          if (sel.originalStep === "LensColor") {
            sel.key = "color";
            return sel;
          }
          return sel;
        }
        if (sel.value === "Clear" || sel.value === "Sun") {
          sel.key = null;
          return sel;
        } else {
          let titles = {};
          if (lensesDataContent.treatmentFamily) {
            Object.keys(lensesDataContent.treatmentFamily).forEach((tfKey) => {
              titles[lensesDataContent.treatmentFamily[tfKey].title] = {
                value: tfKey,
                key: "treatmentFamily",
              };
            });
          }
          if (lensesDataContent.transition) {
            Object.keys(lensesDataContent.transition).forEach((transKey) => {
              titles[lensesDataContent.transition[transKey].title] = {
                value: transKey,
                key: "transition",
              };
              titles[transKey] = {
                value: transKey,
                key: "transition",
              };
            });
          }
          if (lensesDataContent.blueLight) {
            Object.keys(lensesDataContent.blueLight).forEach((blKey) => {
              titles[lensesDataContent.blueLight[blKey].title] = {
                value: blKey,
                key: "blueLight",
              };
              titles[blKey] = {
                value: blKey,
                key: "blueLight",
              };
            });
          }
          sel.value = titles?.[sel.title]?.value;
          sel.key = titles?.[sel.title]?.key;
          return sel;
        }
      });

    if (!stepSelections.length) {
      let ret = flowTree.map((level) => {
        level.options = level.options.filter(
          (opt) =>
            opt.step !== "LensColor" &&
            (!opt.prevChain ||
              !opt.prevChain.find((step) => step.step === "LensColor"))
        );
        if (!level.options.length) {
          return null;
        }
        return level;
      });
      ret = ret.filter((level) => level);
      return ret;
    }

    if (workflow.isLastStep()) {
      let currentPackage = null;
      if (currentPackages.length > 1) {
        //this can happen because sometimes currentPackages doesn't update in time
        //so we are left with two addOns (for the other selections it doesn't happen)
        //and we need to distinguish between the packages by looking at stepSelections
        let addOnsSelection = stepSelections.find(
          (sel) => sel.originalStep === "AddOns"
        );
        if (addOnsSelection) {
          let premiumSelected = addOnsSelection.attributes?.isPremium;
          let standardPackage = getCheapPackageFromList(currentPackages);
          let currentPackageParsed = currentPackages.map((pkg) => {
            return {
              frame: pkg.frame,
              lensPackage: {
                ...pkg.lensPackage,
                listPrice: parseFloat(pkg.lensPackage.listPrice),
              },
            };
          });
          let premiumPackage = maxBy(
            currentPackageParsed,
            "lensPackage.listPrice"
          );
          currentPackage = premiumSelected ? premiumPackage : standardPackage;
        } else {
          currentPackage = currentPackages[0];
        }
      } else {
        currentPackage = currentPackages[0];
      }

      let foundTreeOption = null;
      let foundLevel = null;
      flowTree.forEach((level) => {
        level.options.forEach((opt) => {
          let found = opt.packages.find(
            (pkg) =>
              pkg.lensPackage.catEntryId ===
              currentPackage.lensPackage.catEntryId
          );
          if (found) {
            foundTreeOption = opt;
            foundLevel = level.level;
            return;
          }
        });
      });
      if (foundTreeOption) {
        let prevChain = foundTreeOption.prevChain;
        let newFlowTree = [];
        if (prevChain) {
          prevChain.forEach((sel) => {
            let level = flowTree[sel.level];
            level.options = level.options.filter((opt) =>
              opt.step === "Type"
                ? opt.option === frameOnlyKey
                : opt.option === sel.option
            );
            level.options = level.options.filter(
              (opt) =>
                !opt.prevChain ||
                opt.prevChain.every((prev) =>
                  prevChain.find((elem) => prev.option === elem.option)
                )
            );
            if (level.options.length) {
              newFlowTree.push(level);
            }
          });
          newFlowTree.push({
            level: foundLevel,
            options: [foundTreeOption],
          });
          return newFlowTree;
        }
      }
    }

    let ret = flowTree.map((level) => {
      level.options = level.options.filter(
        (opt) =>
          !stepSelections.find((sel) => sel.originalStep === opt.step) ||
          !stepSelections.find(
            (sel) => sel.key === opt.key && sel.value === opt.option
          )
      );

      level.options = level.options.filter(
        (opt) =>
          !opt.prevChain ||
          (stepSelections.length <= opt.prevChain.length &&
            stepSelections.every((sel) =>
              opt.prevChain.find(
                (prev) => prev.key === sel.key && prev.option === sel.value
              )
            )) ||
          (stepSelections.length > opt.prevChain.length &&
            opt.prevChain.every((prev) =>
              stepSelections.find(
                (sel) => sel.key === prev.key && sel.value === prev.option
              )
            ))
      );

      if (!level.options.length) {
        return null;
      }

      return level;
    });

    ret = ret.filter((level) => level);

    let colorStepPassed = stepSelections.find(
      (sel) => sel.originalStep === "LensColor"
    );
    if (!colorStepPassed) {
      ret = ret.map((level) => {
        level.options = level.options.filter(
          (opt) =>
            opt.step !== "LensColor" &&
            (!opt.prevChain ||
              !opt.prevChain.find((step) => step.step === "LensColor"))
        );
        if (!level.options.length) {
          return null;
        }
        return level;
      });
    }

    ret = ret.filter((level) => level);
    return ret;
  };

  const computeRIACallBlocks = (
    firstStepKey: string,
    flowTree: any[],
    frameOnlyKey: string,
    moveForward: boolean
  ) => {
    const processFlowTree = (flowTree: any[]) => {
      let seenCatEntryIDs = new Set<string>();
      return flowTree
        .map((level) => {
          let ret = { level: level.level, options: [] };
          ret.options = level.options
            .map((opt) => {
              let ret = { ...opt, packages: [] };
              let insPrice = opt.packages[0].lensPackage.insPrice;
              if (
                insPrice === undefined ||
                insPrice === null ||
                isNaN(insPrice)
              ) {
                ret.packages = [opt.packages[0]];
              } else if (
                workflow.isLastStep() &&
                RIAParams.method === DiscountMethod.STANDARD_REVIEW
              ) {
                ret.packages = [opt.packages[0]];
              }
              if (ret.packages.length) {
                let catEntryID = ret.packages[0].lensPackage.catEntryId;
                if (!seenCatEntryIDs.has(catEntryID)) {
                  seenCatEntryIDs.add(catEntryID);
                } else {
                  return null;
                }
              } else {
                return null;
              }
              return ret;
            })
            .filter((opt) => opt);
          if (!ret.options.length) {
            return null;
          }
          return ret;
        })
        .filter((level) => level);
    };

    if (!firstStepKey) {
      return null;
    }

    flowTree = pruneFlowTreeAtCurrentStep(flowTree, frameOnlyKey);

    const N = RIAParams.n;
    const K = RIAParams.k;
    const callBlocks: RIAAPICall[][] = [];

    let processedFlowTree = processFlowTree(flowTree);

    if (
      workflow.isLastStep() &&
      RIAParams.method === DiscountMethod.STANDARD_REVIEW
    ) {
      //remove the current insPrice from the packages so that the loader will display
      let pkgList = [];
      processedFlowTree.forEach((level) => {
        pkgList = [...pkgList, ...level.options[0].packages];
      });
      pkgList = pkgList.map((pkg) => {
        let newFrame = {
          ...pkg.frame,
          insPrice: null,
        };
        let newLensPackage = {
          ...pkg.lensPackage,
          insPrice: null,
        };
        let toRet = {
          frame: newFrame,
          lensPackage: newLensPackage,
        };
        return toRet;
      });
      dispatch(
        configActions.setInsurancePackage({
          packages: pkgList,
          clearPrice: true,
          frame: configContent.data.frame,
        })
      );
    }

    if (workflow.isLastStep()) {
      let insuranceReviewObject = {};
      if (processedFlowTree.length) {
        processedFlowTree.forEach((level) => {
          if (level.options.length) {
            let step = level.options[0].step;
            let pkg = level.options[0].packages[0];
            if (step !== "Type") {
              insuranceReviewObject[step] = {
                step: step,
                catEntryId: pkg.lensPackage.catEntryId,
              };
            }
          }
        });
      }
      flowTree.forEach((level) => {
        if (level.options.length) {
          let step = level.options[0].step;
          let pkg = level.options[0].packages[0];
          if (step !== "Type") {
            insuranceReviewObject[step] = {
              step: step,
              catEntryId: pkg.lensPackage.catEntryId,
            };
          }
        }
      });
      dispatch(
        insuranceActions.setReviewInsuranceObject(insuranceReviewObject)
      );
    }

    let blockIndex = 0;
    let callIndex = 0;
    let callPackages = 0;
    processedFlowTree.forEach((level) => {
      let frameOnlyOption = level.options[0].option === frameOnlyKey;
      if (frameOnlyOption) {
        if (!callBlocks[blockIndex]) {
          callBlocks[blockIndex] = [
            {
              type: RIAAPICallType.SINGLE_LP,
              packages: level.options[0].packages,
            },
          ];
        } else {
          callBlocks[blockIndex].push({
            type: RIAAPICallType.SINGLE_LP,
            packages: level.options[0].packages,
          });
        }
        callPackages = 0;
        callIndex++;
        if (callIndex === N) {
          callIndex = 0;
          blockIndex++;
        }
        level.options.shift();
      }
      level.options.forEach((opt) => {
        if (!callBlocks[blockIndex]) {
          callBlocks[blockIndex] = [
            {
              type: RIAAPICallType.SINGLE_LP,
              packages: opt.packages,
            },
          ];
          callPackages++;
        } else {
          let lastCall = callBlocks[blockIndex][callIndex];
          if (!lastCall) {
            callBlocks[blockIndex].push({
              type: RIAAPICallType.SINGLE_LP,
              packages: opt.packages,
            });
            callPackages++;
          } else {
            lastCall.packages = lastCall.packages.concat(opt.packages);
            if (lastCall.packages.length > 1) {
              lastCall.type = RIAAPICallType.MULTIPLE_LP;
            }
            callPackages++;
          }
        }
        if (callPackages === K) {
          callIndex++;
          callPackages = 0;
        }

        if (callIndex === N) {
          callIndex = 0;
          callPackages = 0;
          blockIndex++;
        }
      });
    });

    return callBlocks;
  };

  const [callBlocksQueue, setCallBlocksQueue] = useState(null);

  useEffect(() => {
    if (insuranceEnabled && stepChains && currentStep && RIAParams) {
      if (!cartMode || (cartMode && loadPreselectEnabled == false)) {
        let firstStep = currentStep
          ? currentStep.key
          : configContent.steps &&
            configContent.steps.length &&
            configContent.steps[0].key;
        let { flowTree, frameOnlyKey } = computeRIAFlowTree(
          configPackages,
          configContent,
          stepChains,
          currentPrescription,
          lensesDataContent,
          { enableBrandLastStep }
        );
        console.log(flowTree, frameOnlyKey);
        let callBlocks = computeRIACallBlocks(
          firstStep,
          flowTree,
          frameOnlyKey,
          true
        );
        setCallBlocksQueue(callBlocks);
      }
    }
  }, [
    insuranceEnabled,
    RIAParams,
    stepChains,
    currentPrescription,
    currentStep,
    cartMode,
    loadPreselectEnabled,
  ]);

  const onBlockCompleted = (catEntryIDs: string[]) => {
    let newQueue = [...callBlocksQueue];
    newQueue.shift();
    setCallBlocksQueue(newQueue);
  };

  useEffect(() => {
    if (callBlocksQueue && callBlocksQueue.length) {
      let manager = RIAManager.getInstance(
        configContent,
        RIAParams.method as DiscountMethod,
        dispatch,
        (catEntryIDs: string[]) => onBlockCompleted(catEntryIDs)
      );
      let call = callBlocksQueue[0];
      if (!isLensAdvisorOpened) {
        manager.callAPI(call);
      }
    }
  }, [callBlocksQueue]);

  const getCorrectEnvironment = () => {
    const scripts = Array.from(document.getElementsByTagName("script"));
    const rxcScript = scripts.find((script) => {
      return script.src.includes("rxc.js");
    });
    const splittedScript = rxcScript?.src.split(/[//]/);
    const env = splittedScript?.[3];
    return env;
  };

  useEffect(() => {
    if (currentBrand && RIAConfigBaseUrl) {
      if (process.env.DEV) return;
      let brand = currentBrand === "default" ? "lenscrafters" : currentBrand;
      const environment = getCorrectEnvironment();
      const ecomInsurance = ecomInsuranceFunction?.() || {};
      axios
        .get(RIAConfigBaseUrl)
        .then((res) => {
          try {
            const RIAParams = getInsuranceParams(
              res.data,
              ecomInsurance,
              brand,
              environment
            );
            setRIAParams(RIAParams);
            dispatch(insuranceActions.setPricingMethodConfig(RIAParams.method));
          } catch (error) {
            throw error;
          }
        })
        .catch((err) => {
          console.error(err);
          console.error(
            "Unable to retreive RIA configuration at url: " + RIAConfigBaseUrl
          );
          try {
            //@ts-ignore
            window.tealium_data2track.push({
              id: "Error",
              Error_Code: "RX Configurator: Loading error",
              Error_Details: err,
              Error_Source: "Client",
            });
          } catch (error) {
            console.error(
              "Error during tealium_data2track push. Check if tealium_data2track exists in the window."
            );
          }
          if (!configContent.insuranceModule?.isLogged()) {
            dispatch(insuranceActions.setInsuranceEnabled(false));
          } else {
            configContent.insuranceModule.removeInsuranceBenefits() ?? null;
            dispatch(insuranceActions.setInsuranceEnabled(false));
            /* dispatch(modalsActions.setShowInsuranceErrorPopup(true)); */
          }
        });
    }
  }, [RIAConfigBaseUrl, currentBrand, insuranceEnabled]);
};
