import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";

export type VirtualMirror = {
  showVirtualMirror?: boolean;
  transitionsTechnologiesMap: {};
  transitionsSwatchesMap: [];
  lensesList: [];
  lensIdentifier: string;
  vmappConfig: {};
  initializationStepKey: string;
  showVirtualMirrorButton: boolean;
  globalVmapp: any;
};

const initialState: VirtualMirror = {
  showVirtualMirror: false,
  transitionsTechnologiesMap: {},
  transitionsSwatchesMap: [],
  lensesList: [],
  lensIdentifier: "",
  vmappConfig: {},
  initializationStepKey: "",
  showVirtualMirrorButton: false,
  globalVmapp: null,
};

const virtualMirrorSlice = createSlice({
  name: "virtualMirror",
  initialState: initialState,
  reducers: {
    setVmappConfig: (state, { payload }: PayloadAction<any>) => {
      state.vmappConfig = payload;
    },
    setGlobalVmapp: (state, { payload }: PayloadAction<any>) => {
      state.globalVmapp = payload;
    },
    setInitializationStepKey: (state, { payload }: PayloadAction<any>) => {
      state.initializationStepKey = payload;
    },
    setShowVirtualMirror: (state, { payload }: PayloadAction<any>) => {
      state.showVirtualMirror = payload;
    },
    setTransitionsTechnologiesMap: (state, { payload }: PayloadAction<any>) => {
      state.transitionsTechnologiesMap = payload;
    },
    setTransitionsSwatchesMap: (state, { payload }: PayloadAction<any>) => {
      state.transitionsSwatchesMap = payload;
    },
    setLens: (state, { payload }: PayloadAction<any>) => {
      const color = payload.color;
      const lensIdentifier = state.lensesList.find(
        (l) => l?.clen === color?.clen
      )?.lensIdentifier;
      if (!lensIdentifier || state.lensIdentifier === lensIdentifier) return;
      state.lensIdentifier = lensIdentifier;
    },
    setLensesList: (state, { payload }: PayloadAction<any>) => {
      // console.log(payload)
      // console.log(current(state.transitionsTechnologiesMap))
      // console.log(current(state.transitionsSwatchesMap))
      let lensTechnology = payload.id.split("$")[1];
      if (!payload.id.split("$")[1]) {
        // capitalize first letter in case of "transition" (non-prescription)
        lensTechnology =
          payload.id.charAt(0).toUpperCase() + payload.id.slice(1);
      }

      if (lensTechnology === "TransitionXtractive") {
        // LC RXPANEL-4253
        lensTechnology = "xtractive";
      }
      if (lensTechnology !== "Transitions Salt F") {
        if (lensTechnology.includes(" ")) {
          lensTechnology = lensTechnology.split(" ")[0];
        }
        lensTechnology = lensTechnology.toLowerCase();
        lensTechnology =
          lensTechnology.charAt(0).toUpperCase() + lensTechnology.slice(1);
      }

      const lensesList = payload.colors
        .map((color) => {
          const lensIdentifier = state.transitionsSwatchesMap.find(
            (s) =>
              s.lensName === state.transitionsTechnologiesMap[lensTechnology] &&
              s.clen &&
              s.clen === color.clen
          )?.colorCode;
          return {
            lensIdentifier: lensIdentifier,
            name: color.title,
            colorName: color.title,
            swatchImage: undefined,
            clen: color.clen,
            lensTechnology: payload.id,
          };
        })
        .filter((s) => s.lensIdentifier);

      // if (state.lensesList.length === lensesList.length) {
      //   return;
      // }
      // console.log(state.lensesList.length);

      console.log(lensesList);
      state.lensesList = lensesList;
    },
    setShowVirtualMirrorButton: (state, { payload }: PayloadAction<any>) => {
      state.showVirtualMirrorButton = payload;
    },
  },
});

export const virtualMirrorReducer = virtualMirrorSlice.reducer;
export const virtualMirrorActions = virtualMirrorSlice.actions;
